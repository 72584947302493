
import ahoy from "ahoy.js"

document.addEventListener("DOMContentLoaded", function() {
  // if (!document.querySelector('[data-user]')) return

  const userId = document.querySelector('[data-user]')?.dataset?.user || ''
  const userPlan = document.querySelector('[data-user]')?.dataset?.userPlan || ''
  const base = { userId, userPlan, userAgent: window.navigator.userAgent }

  const recordAhoy = {
    now: ({ event, data = {} }) => {
      const info = { ...base, ...data }
      ahoy.track(`${event}`, info);
      Logger(`Ahoy: ${event}`, info)
    },
    event: ({ element, trigger = 'click', event, data = {} }) => {
      const el = document.querySelector(element)
      if (!el) return;

      el.addEventListener(trigger, (e) => {
        const info = {
          ...base,
          ...data,
          value: e?.target?.value
        }

        ahoy.track(`${event}`, info);
        Logger(`Ahoy: ${event}`, info)
      })
    }
  }

  window.recordAhoy = recordAhoy

  // changes the mode
  recordAhoy.event({ element: '#mode', trigger: 'change', event: 'changed_mode' });

  // changed script language
  recordAhoy.event({ element: '#select-language', trigger: 'change', event: 'changed_language' });

  // records a video
  recordAhoy.event({ element: '#record', event: 'clicked_record_video' });

  // opens the Remote modal
  recordAhoy.event({ element: '#js-remote-directions', event: 'clicked_remote' });

  // clicks Overlay
  recordAhoy.event({ element: '#js-overlay-start', event: 'clicked_overlay' });

  // mirror local screen
  recordAhoy.event({ element: '#mirror-local', event: 'mirrored_local' });

  // mirror remote screen
  recordAhoy.event({ element: '#mirror-remote', event: 'mirrored_remote' });

  // started teleprompter
  recordAhoy.event({ element: '#start', event: 'clicked_start' });
})
